import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Ensure this is also present
import './tailwind.css'; // Import Tailwind CSS
import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);