import React from "react";
import { Link } from 'react-router-dom';
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import logo from '../assets/logo/bright_logo.jpg';
export default function NavbarDefault() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        {/* <svg
          height="20px"
          width="20px"
          version="1.1"
          id="_x32_"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <style type="text/css">{`.st0{fill:#000000;}`}</style>
            <g>
              <path
                className="st0"
                d="M511.999,288.414l-12.415-23.7l-104.08,54.502l-37.624-37.622l86.994-63.475l-13.337-18.288l-89.861,65.558 l-39.534-39.535l76.474-76.465l-16.006-16.015l-76.474,76.474l-39.526-39.526l65.558-89.86L293.88,67.123l-63.466,86.994 l-37.632-37.623l54.504-104.088L223.585,0L78.438,277.186l-1.433-1.442l-52.131,52.13c-9.58,9.572-16.377,21.098-20.381,33.479 c-6.014,18.593-5.865,39.032-0.304,58.754c5.577,19.738,16.6,38.858,32.713,54.988c21.509,21.485,48.316,33.941,74.859,36.445 c13.272,1.244,26.494-0.024,38.884-4.036c12.382-4.004,23.907-10.8,33.48-20.381l52.131-52.122l-1.45-1.45L511.999,288.414z M374.49,330.214l-60.929,31.907L285.7,334.25l53.671-39.156L374.49,330.214z M323.166,278.89l-53.662,39.164l-29.781-29.772 l46.413-46.421L323.166,278.89z M223.716,304.28l27.276,27.276l-59.075,43.102l-19.285-19.286L223.716,304.28z M156.626,339.366 l-19.285-19.285l43.101-59.076l27.269,27.276L156.626,339.366z M233.108,188.833l37.022,37.021l-46.414,46.414l-29.772-29.764 L233.108,188.833z M181.785,137.51l35.119,35.119L177.748,226.3l-26.008-26l-5.964,5.972L181.785,137.51z M138.882,219.446 l25.364,25.364l-43.102,59.075l-17.382-17.382L138.882,219.446z M165.21,468.201c-6.484,6.476-14.178,11.048-22.811,13.857 c-12.918,4.202-28.035,4.3-43.242,0.008c-15.2-4.292-30.398-12.942-43.332-25.892c-17.267-17.243-26.88-38.538-28.726-58.433 c-0.939-9.943,0.05-19.516,2.842-28.141c2.809-8.634,7.373-16.328,13.848-22.811l44.123-44.123l121.42,121.421L165.21,468.201z M208.122,390.854l59.066-43.093l25.366,25.357l-67.058,35.118L208.122,390.854z"
              />
            </g>
          </g>
        </svg> */}
        <Link to="/match" className="flex items-center font-bold text-base text-black hover:text-transparent bg-clip-text 
               bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500">MATCHES</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        {/* <svg
          height="20px"
          width="20px"
          fill="#000000"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 220 220"
          xmlSpace="preserve"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <path d="M110,0C49.346,0,0,49.346,0,110s49.346,110,110,110s110-49.346,110-110S170.654,0,110,0z M110,210 c-55.14,0-100-44.86-100-100S54.86,10,110,10s100,44.86,100,100S165.14,210,110,210z"></path>
              <path d="M110,19.5c-49.902,0-90.5,40.598-90.5,90.5s40.598,90.5,90.5,90.5s90.5-40.598,90.5-90.5S159.902,19.5,110,19.5z M110,197.5c-48.248,0-87.5-39.252-87.5-87.5S61.752,22.5,110,22.5s87.5,39.252,87.5,87.5S158.248,197.5,110,197.5z"></path>
              <path d="M147.623,86.373c0-15.321-11.914-27.902-26.962-28.989v12.06c8.419,1.046,14.962,8.232,14.962,16.929 s-6.542,15.883-14.962,16.929v12.29c8.419,1.046,14.962,8.232,14.962,16.929s-6.542,15.883-14.962,16.929v12.06 c15.048-1.087,26.962-13.668,26.962-28.989c0-9.391-4.475-17.755-11.404-23.074C143.148,104.128,147.623,95.764,147.623,86.373z"></path>
              <polygon points="104.661,149.595 95.699,149.595 95.699,115.447 104.661,115.447 104.661,103.447 95.699,103.447 95.699,69.299 104.661,69.299 104.661,57.299 83.699,57.299 83.699,161.595 104.661,161.595 "></polygon>
              <rect x="107.661" y="50.564" width="10" height="118.871"></rect>
            </g>
          </g>
        </svg> */}

        <Link to="/paynow" className="flex items-center font-semibold text-base text-black hover:text-transparent bg-clip-text 
               bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500">PAY NOW</Link>
      </Typography>
      {/* <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="flex items-center gap-x-2 p-1 font-medium"
      >
        <svg
          height="20px"
          width="20px"
          fill="#000000"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 220 220"
          xmlSpace="preserve"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <path d="M110,0C49.346,0,0,49.346,0,110s49.346,110,110,110s110-49.346,110-110S170.654,0,110,0z M110,210 c-55.14,0-100-44.86-100-100S54.86,10,110,10s100,44.86,100,100S165.14,210,110,210z"></path>
              <path d="M110,19.5c-49.902,0-90.5,40.598-90.5,90.5s40.598,90.5,90.5,90.5s90.5-40.598,90.5-90.5S159.902,19.5,110,19.5z M110,197.5c-48.248,0-87.5-39.252-87.5-87.5S61.752,22.5,110,22.5s87.5,39.252,87.5,87.5S158.248,197.5,110,197.5z"></path>
              <path d="M147.623,86.373c0-15.321-11.914-27.902-26.962-28.989v12.06c8.419,1.046,14.962,8.232,14.962,16.929 s-6.542,15.883-14.962,16.929v12.29c8.419,1.046,14.962,8.232,14.962,16.929s-6.542,15.883-14.962,16.929v12.06 c15.048-1.087,26.962-13.668,26.962-28.989c0-9.391-4.475-17.755-11.404-23.074C143.148,104.128,147.623,95.764,147.623,86.373z"></path>
              <polygon points="104.661,149.595 95.699,149.595 95.699,115.447 104.661,115.447 104.661,103.447 95.699,103.447 95.699,69.299 104.661,69.299 104.661,57.299 83.699,57.299 83.699,161.595 104.661,161.595 "></polygon>
              <rect x="107.661" y="50.564" width="10" height="118.871"></rect>
            </g>
          </g>
        </svg>

        <Link to="/contact" className="flex items-center">Contact us</Link>
      </Typography> */}
    </ul>
  );

  return (
    <Navbar className="mx-auto w-full px-4 py-2 lg:px-8 lg:py-4 border border-gray-100 shadow-lg rounded-lg">
      <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="#"
          className="mr-4 cursor-pointer py-1.5 font-medium flex"
        >
          <div className="flex gap-2">
            <img src={logo} alt="logo-ct" className="w-10 gap-2 rounded" />
            <Link
              to="/"
              className="flex items-center font-bold text-lg text-transparent bg-clip-text 
                      bg-gradient-to-r from-gray-800 to-orange-800 transition-all duration-500"
            >
              BRIGHT BADMINTON
            </Link>
          </div>
        </Typography>
        <div className="hidden lg:block ml-auto mr-10">{navList}</div>
        <div className="flex items-center gap-x-1">
          <Button
            variant="text"
            size="sm"
            className="hidden lg:inline-block border border-gray-300 rounded-lg px-4 py-2 font-semibold text-sm text-gray-700 hover:bg-gray-100"
          >
            <span>Log In (Admin)</span>
          </Button>
        </div>

        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="black"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="black"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      <MobileNav open={openNav}>
        <div className="container mx-auto">
          {navList}
          <div className="flex items-center gap-x-1">
            <Button fullWidth variant="text" size="lg" className="">
              <span>Log In (Admin)</span>
            </Button>
            {/* <Button fullWidth variant="gradient" size="sm" className="">
              <span>Sign in</span>
            </Button> */}
          </div>
        </div>
      </MobileNav>
    </Navbar>
  );
}
