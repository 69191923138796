// Home.js
import React from 'react';
import Team from '../components/Team';

function Contact() {
  return (
    <div>
      <h2 className="mt-4 mb-4">Contact Page</h2>
    </div>
  );
}

export default Contact;
