import React from "react";

function Team({
  players,
  imageWidth = "w-32",
  imageMaxWidth = "max-xl:w-14",
  imageMarginX = "mx-2",
  rotateDegree = "rotate-90",
}) {
  const defaultImage =
    "https://admin.connect2play.site/static/images/bright-icon-rotate.jpg";

  return (
    <div className="flex items-center">
      {players.map((player, index) => (
        <div key={index} className="flex flex-col items-center mx-0.5">
          <div
            className={`${imageWidth} ${imageMaxWidth} ${imageMarginX} aspect-w-1 aspect-h-1 border border-gray-300 rounded-lg overflow-hidden flex items-center justify-center`}
          >
            {player.image && !player.image.endsWith("None") ? (
              <img
                src={player.image}
                alt={player.name}
                className={`object-cover w-full h-full ${rotateDegree}`}
              />
            ) : (
              <img
                src={defaultImage}
                alt="Default"
                className={`object-cover w-full h-full ${rotateDegree}`}
              />
            )}
          </div>
          <span className="mt-2 text-center">{player.name}</span>
        </div>
      ))}
    </div>
  );
}

export default Team;
