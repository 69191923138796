import { Typography } from "@material-tailwind/react";
import logo from '../assets/logo/bright_logo.jpg';
import { Link } from 'react-router-dom';
export default function FooterWithLogo() {
  return (
    <footer className="w-full bg-[#212121] text-white p-8">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
        {/* <img src={logo} alt="logo-ct" className="w-10" /> */}
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          {/* <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              About Us
            </Typography>
          </li> */}
          {/* <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              License
            </Typography>
          </li> */}
          {/* <li>
            <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Contribute
            </Typography>
          </li> */}
          <li>
            {/* <Typography
              as="a"
              href="#"
              color="blue-gray"
              className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
            <Link to="/contact" className="flex items-center">Contact us</Link>
            </Typography> */}
          </li>
        </ul>
      </div>
      <hr className="mt-8 mb-4 border-gray-600 border-1" />
      <Typography color="blue-gray" className="text-center font-bold">
        <Link to="/#">&copy; 2024 SN Tech Solutions</Link>
      </Typography>
    </footer>
  );
}